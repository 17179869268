<template>
  <div>
    <div id="loader" v-if="loadingUserData">
      <loader></loader>
    </div>
    <div v-else id="zform" class="alignPDL">
      <noscript>
        <img style="display:none;" :src="src" />
      </noscript>
      <!--    оставить здесь-->
      <div class="sec_2">
        <div id="anketaSteps">
          <div id="screenFormLayout">
            <uiProductAmountSelector v-if="currentScreen === 'calc'"></uiProductAmountSelector>
            <screenFio v-if="currentScreen === 'fio'"></screenFio>
            <screenPayment v-if="currentScreen === 'payment'"></screenPayment>
            <screenSigningLoan v-if="currentScreen === 'signingLoan'"></screenSigningLoan>
            <screenWaiting v-if="currentScreen === 'waiting'"></screenWaiting>
            <screenThankYouPage v-if="currentScreen === 'thankYouPage'"></screenThankYouPage>
            <screenRejection v-if="currentScreen === 'rejection'"></screenRejection>
            <screenFullFail v-if="currentScreen === 'fullFail'"></screenFullFail>
            <sceenCathError v-if="currentScreen === 'cathError'"></sceenCathError>
          </div>
        </div>
        <div id="steps" v-if="showSteps">
          <div class="nextStepBlock" v-show="isPdl && currentScreenNumber < screenCount - 1">
            <p>Шаг {{ currentScreenNumber }} из {{ screenCount - 1 }}</p>
            <button v-on:click="nextBlock($event)">следующий шаг</button>
          </div>
          <div class="nextStepBlock" v-show="!isPdl || currentScreenNumber === screenCount - 1">
            <p v-show="isPdl">
              Шаг {{ currentScreenNumber }} из {{ screenCount - 1}}
            </p>
            <button v-if="isPdl || !loadingGeo" v-on:click="isPdl ? finishPdl() : finishBase()">
              Завершить оформление
            </button>
            <button v-else class="disabled">
              Идет загрузка
            </button>
          </div>

          <div>
            <div>
              <img alt="" src="img/ico_10_lock.svg" />
              <span>Гарантируем безопасность и сохранность ваших данных</span>
            </div>
            <div v-if="currentScreen === 'fio'">
              <label for="chk2"><input id="chk2" type="checkbox" checked /></label>
              <p>
                Подтверждаю достоверность введенных данных и даю
                <br class="pcOnly" />согласие на обработку персональных данных
                <a target="_blank"
                  href="https://lnd.msk.finmoll.com/assets/pdf/consent_to_PD_processing.pdf">Подробнее</a>
              </p>
            </div>
            <div v-if="currentScreen === 'fio'">
              <label for="chk"><input id="chk" type="checkbox" checked /></label>
              <p>
                Подтверждаю достоверность введенных данных и даю
                <br class="pcOnly" />согласие на получение рекламы
                <a target="_blank" href="https://lnd.msk.finmoll.com/admd/upload/consentToAdvertising.pdf">Подробнее</a>
              </p>
            </div>
          </div>
        </div>
        <!-- <loader v-show="isLoading"></loader> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import tracing from '@/common/tracing';

export default {
  name: 'repeatLoan',
  data() {
    return {
      src: 'https://score.juicyscore.com/savedata/?isJs=0',
      clicked: false,
      timerId: null
    };
  },
  components: {
    loader: () => import('./components/uiLoader'),
    screenFio: () => import('./formScreenFio'),
    screenPayment: () => import('./formScreenPaymentSelector'),
    screenSigningLoan: () => import('./formScreenSigningLoan'),
    screenWaiting: () => import('./formScreenWaiting'),
    screenThankYouPage: () => import('./formScreenTYpage'),
    screenRejection: () => import('./formScreenRejection'),
    screenFullFail: () => import('./formScreenFullFail'),
    uiProductAmountSelector: () => import('./uiProductAmountSelector'),
    sceenCathError: () => import('./formScreenCathError')
  },
  computed: {
    ...mapState({
      nextScreen: (state) => state.repeatLoan.system.nextScreen,
      currentScreen: (state) => state.repeatLoan.system.currentScreen,
      calc_week: (state) => state.repeatLoan.sendData.CALC_WEEK,
      availableScreens: (state) => state.repeatLoan.system.availableScreens,
      loadingUserData: (state) => state.repeatLoan.system.loading,
      loadingGeo: (state) => state.repeatLoan.geo.isLoading,
      hasGeo: (state) => state.repeatLoan.geo.data
    }),
    ...mapGetters('repeatLoan', [
      'screenCount',
      'currentScreenNumber',
      'isPdl',
      'showSteps',
      'getSession'
    ]),

    phoneForProduct() {
      return {
        href: !this.isPdl ? '88006009990' : '88006000685',
        view: !this.isPdl ? '8 800 600 99 90' : '8 800 600 06 85'
      };
    }
  },

  mounted() {
    const elem = document.createElement('script');
    // elem.src = '//code.jivo.ru/widget/HlZ14RdVxQ';
    let srcGenerate = src => elem.src = src;
    srcGenerate('//code.jivo.ru/widget/HlZ14RdVxQ');
    srcGenerate('//score.juicyscore.com/static/js.js');
    elem.onload = () => this.juicyScoreConnect();
    document.head.appendChild(elem);
    this.timerId = setTimeout(async () => await this.sendTrace(), 3000)
  },
  destroyed() {
    const elem = document.getElementsByTagName('jdiv').item(0);
    elem.parentNode.removeChild(elem);
    clearTimeout(this.timerId)
  },
  methods: {
    juicyScoreConnect () {
      try {
        tracing.traceInput({
          action: 165,
          refs: 'score_juicyscore_sessionId_errorMsg',
          value: 'run window.juicyScoreApi.getSessionId()',
        });
        window.juicyScoreApi.getSessionId().then(sessionId => {
          tracing.traceInput({
            action: 165,
            refs: 'score_juicyscore_sessionId',
            value: sessionId,
          })
        })
      } catch (error) {
        tracing.traceInput({
          action: 165,
          refs: 'score_juicyscore_sessionId',
          value: null,
        });
        tracing.traceInput({
          action: 165,
          refs: 'score_juicyscore_sessionId_errorMsg',
          value: error.toString(),
        });
        if (window.juicyScoreApi !== undefined) {
          tracing.traceInput({
            action: 165,
            refs: 'score_juicyscore_sessionId_errorMsg',
            value: JSON.stringify(window.juicyScoreApi.apiEvents),
          });
        } else {
          tracing.traceInput({
            action :165,
            refs: 'score_juicyscore_sessionId_errorMsg',
            value: 'window.juicyScoreApi undefined',
          });
        }
      }
    },
    async nextBlock() {
      if (this.clicked) {
        return;
      } else {
        this.clicked = true;
      }
      try {
        if (this.currentScreen === 'fio') {
          const isValid = await this.$store.dispatch('repeatLoan/validateForm');
          if (!isValid) {
            console.log('form is not valid');
            document.getElementsByClassName('errFieldBorder')[0].scrollIntoView({
              behavior: 'smooth'
            });
            return;
          }
        }
        if (this.currentScreen === 'payment') {
          const isValid = await this.$store.dispatch('repeatLoan/validateCard');
          if (!isValid) {
            console.log('card is not valid');
            alert('Выберите карту');
            return;
          }
        }
        await this.$store.dispatch('repeatLoan/sendScreenInfo');
        this.$store.dispatch('repeatLoan/changeCurrentScreen', this.nextScreen);
        this.$store.dispatch(
          'repeatLoan/changeNextScreen',
          this.availableScreens[this.currentScreenNumber + 1])
      } catch (e) {
        console.log(e);
      } finally {
        this.clicked = false;
      }
    },
    async finishPdl() {
      if (this.clicked) {
        return;
      } else {
        this.clicked = true;
      }
      try {
        const result = await this.$store.dispatch(
          'repeatLoan/pdlConfirmCodeCheck'
        );

        if (typeof result !== 'boolean') {
          this.$store.dispatch('repeatLoan/changeCurrentScreen', 'fullFail');
        }
        if (result) {
          this.$store.dispatch('repeatLoan/changeNextScreen', 'waiting');
          await this.$store.dispatch('repeatLoan/sendScreenInfo');
          this.$store.dispatch('repeatLoan/changeCurrentScreen', this.nextScreen);
          this.$store.dispatch('repeatLoan/changeNextScreen', undefined);
        } else {
          this.$store.dispatch('repeatLoan/fieldError', {
            target: 'pdlSmsConfCode',
            value: 'Некорректный	код'
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.clicked = false;
      }
    },
    async finishBase() {
      if (!(await this.$store.dispatch('repeatLoan/validateForm'))) {
        console.log('form is not valid');
        return;
      }
      const longId = await this.$store.dispatch('repeatLoan/saveBase');
      if (longId === 0) {
        alert('Заявка не может быть обработана.');
        return;
      }
      await this.$store.dispatch('repeatLoan/setLongId', longId);
      this.$store.dispatch('repeatLoan/changeCurrentScreen', this.nextScreen);
    },
    async sendTrace() {
      // условие, что для 165 экшена время с последнего вызова больше 3 сек
      const last = tracing.getLastTrace();
      if (last && last.action === 165 && Date.now() - last.ts_end < 3000) {
        this.timerId = setTimeout(async () => await this.sendTrace(), Date.now() - last.ts_end);
      } else {
        await tracing.sendTrace(this.getSession.session_id, this.getSession.token);
        this.timerId = setTimeout(async () => await this.sendTrace(), 3000);
      }
    },
  }
};
</script>

<style scoped>

/*остальные стили*/

select,input{background: inherit}

h2 { font-size: 5.29vw }
h3 { color: #222721; font-size: 4.41vw }
p { font-size: 3.53vw }
a { color: #43AC34 }
span { font-size: 2.35vw }
div { font-size: 3.53vw }
label { font-size: 2.94vw }

* { font-family: "Lato" }
/*.align { margin: unset; width: auto }*/
button { font-family: 'Lato'; font-size: 3.53vw; line-height: normal;}
button.disabled { opacity: 0.5; }
.fontBold { font-family: 'Lato-Bold' }
#steps { padding: 5.88vw }
#steps > div { margin-bottom: 6.93vw; display: flex; align-items: center; flex-direction: column; }
#steps > div > div { display: flex; align-items: center; margin-bottom: 5.88vw }
#steps > div > div > img { margin: 0 4.80vw 0 1.33vw; width: 3.53vw; height: 3.53vw;}
#steps > div > div > label > input { margin: 0 4.80vw 0 1.33vw; width: 5.29vw; height: 5.29vw; border-radius: 5px; background: #f5f5f5; color: #222721 }
#steps > div > div > span { color: rgba(19,21,21,.5019607843137255); font-size: 2.94vw}
#steps > div > div > p { color: rgba(19,21,21,.5019607843137255); font-size: 2.94vw}
#steps > div > div > label:last-child { color: rgba(19,21,21,.5019607843137255)}
#steps > div > div > label > a { color: #43ac34; cursor: pointer }
#steps .nextStepBlock { display: flex; align-items: center; flex-direction: row; justify-content: space-between; }
#steps .nextStepBlock > p { display: flex; align-items: center }
#steps .nextStepBlock > button { border-radius: 2.67vw; width: 56vw; padding: 4.27vw; height: 12.27vw; letter-spacing: 0;}
#zform .sec_2 .pcOnly { display: none }
.pcOnly { display: none }
#anketaSteps { padding: 5.88vw ;position: relative}
#screenFormLayout  h2 { margin-bottom: 11.47vw; font-size: 6.47vw }
.mobileOnly { display: block }
.sec_2{position: relative}
div.suggestions span { font-size: 4.41vw }
@media (min-width: 760px) {
  /*остальные стили*/

  h2 { font-size: 2.58vw }
  h3 { color: #222721; font-size: 2.10vw }
  p { font-size: 1.13vw }
  a { color: #43AC34 }
  span { font-size: 0.81vw }
  div { font-size: 1.13vw }
  button { font-size: 1.21vw }
  label { font-size: 0.81vw }
  div.suggestions span { font-size: 1.21vw }

  .mobileOnly { display: none }
  .pcOnly { display: block }
  #steps > div { display: flex }
  /*.align { width: 96.77vw; margin: 0 auto; }*/
  .alignPDL { width: 96.77vw; margin: 0 auto; }
  #zform .sec_2 .pcOnly { display: block }
  #anketaSteps { display: flex; padding: 0; justify-content: space-between }
  #screenFormLayout{ width: 100vw }
  #screenFormLayout h2 { margin-bottom: 5.32vw; text-align: left; font-size: 1.94vw }
  #steps { padding: 2.66vw 12.58vw 0 }
  #steps > div { flex-direction: row; margin-bottom: 0 }
  #steps > div > div { display: flex; align-items: center; padding-right: 3.15vw }
  #steps > div > div > img { width: 1.45vw; height: 1.45vw; margin: 0 0.65vw 0 0 }
  #steps > div > div > span { color: #131515; opacity: 0.5; padding-left: 0.65vw; font-size: 0.81vw }
  #steps > div > div > p {  color: #131515; opacity: 0.5; padding-left: 0.65vw; font-size: 0.81vw }
  #steps > div > div > label:last-child {  color: #131515; opacity: 0.5; padding-left: 0.65vw }
  #steps > div > div > label > a { color: #43AC34 }
  #steps > div > div > label > input { width: 1.45vw; height: 1.45vw; margin: 0 0.65vw 0 0}
  #steps .nextStepBlock { margin-bottom: 4.27vw; justify-content: flex-end; }
  #steps .nextStepBlock > p { display: flex; align-items: center; margin-bottom: 0;  }
  #steps .nextStepBlock > button { border-radius: 10px; width: 18vw; padding: 1.29vw 2.10vw; height: 4.03vw; margin: 0 0 0 1.94vw; }
}
@media (min-width: 1240px) {
  /*select,input{appearance: auto;}*/
  /*остальные стили*/

  h2 { font-size: 29px }
  h3 { color: #222721; font-size: 32px }
  p { font-size: 14px }
  a { color: #43AC34; font-size: 12px }
  span { font-size: 10px }
  div { font-size: 14px }
  button { font-size: 15px }
  label { font-size: 10px }
  div.suggestions span { font-size: 15px }

  .alignPDL { width: 1200px; margin: 0 auto; }
  .mobileOnly { display: none }
  .pcOnly { display: block }
  #steps > div { display: flex }
  #zform .sec_2 .pcOnly { display: block }
  #anketaSteps { padding: 0; }
  #screenFormLayout h2 { margin-bottom: 40px ;font-size: 32px }
  #steps { padding: 33px 110px 0 }
  #steps > div { margin-bottom: 48px }
  #steps > div > div { padding-right: 39px; margin-bottom: 0 }
  #steps > div > div > img { width: 18px; height: 18px; margin: 0 8px 0 0 }
  #steps > div > div > span { padding-left: 0 }
  #steps > div > div > label {  padding-left: 0 }
  #steps > div > div > p {  padding-left: 0; font-size: 10px }
  #steps > div > div > label > input { width: 18px; height: 18px; margin: 0 8px 0 0 }
  #steps .nextStepBlock { margin-bottom: 53px }
  #steps .nextStepBlock > button { width: 250px; padding: 16px 15px; height: 50px; margin: 0 0 0 30px }
}

</style>
